 @font-face {
  font-family: 'FontAwesome';
  src: url('assets/fa/fontawesome-webfont.eot?v=4.7.0');
  src: url('assets/fa/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
      url('assets/fa/fontawesome-webfont.woff2?v=4.7.0') format('woff2'), 
      url('assets/fa/fontawesome-webfont.woff?v=4.7.0') format('woff'), 
      url('assets/fa/fontawesome-webfont.ttf?v=4.7.0') format('truetype'), 
      url('assets/fa/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
  @font-face {
      font-family: 'lane';
      src: url('assets/railway/Raleway-Thin.eot');
      src: url('assets/railway/Raleway-Thin.eot?#iefix') format('embedded-opentype'),
          url('assets/railway/Raleway-Thin.woff') format('woff'),
          url('assets/railway/Raleway-Thin.ttf') format('truetype'),
          url('assets/railway/Raleway-Thin.svg') format('svg');
      font-weight: 100;
      font-style: italic;
  }
  @font-face {
    font-family: 'lane';
    src: url('assets/railway/Raleway-ExtraLight.eot');
    src: url('assets/railway/Raleway-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('assets/railway/Raleway-ExtraLight.woff') format('woff'),
        url('assets/railway/Raleway-ExtraLight.ttf') format('truetype'),
        url('assets/railway/Raleway-ExtraLight.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
  @font-face {
      font-family: 'open sans';
      src: url('assets/open_sans/opensans-extrabold-webfont.woff');
      src: url('assets/open_sans/opensans-extrabold-webfont.woff2') format('woff'),
          url('assets/open_sans/opensans-extrabold-webfont.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
  }


#card {
  padding: 10px;
  display: block;
  position: absolute;
  width: 600px;
  height: 600px;            
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  overflow: hidden;
  cursor: default;
  text-align: center;
}
a {
  text-decoration: none;
}
ul{
      list-style-type: none;
      margin: 0;
      padding: 0;
  }
      h1{
    padding: 0;
    margin: 0!important;
    font-family: 'roboto';
    font-size: 2.4rem;
    /* color: #34495e; */
    color: rgba(0,0,0,.3);
  }
    #layout > li:first-child{
      padding-top: 0px;
      
      margin-top: 0px;
    }
        ul{
      list-style-type: none;
      margin: 0;
      padding: 0;
  }
    #layout {
    height: 100%;
  }
  #layout > li {
      margin: auto;
  }
  #layout > li:first-child{
      width: 200px;
      height: 150px;
      padding-top: 0px;
  }
    #side {
      height: 100px;
      margin: auto;
      width: 150px;
  }
  #side > li {
      position: relative;
      width: 100px;
      margin: auto;
  }
    #side > li:first-child {
      margin: auto;
  }
  #side > li:nth-child(2){
      opacity: 0;
      animation: 1s ease-in-out 0s 1 normal forwards running fadeIn;
      -moz-animation: 1s ease-in-out 0s 1 normal forwards running fadeIn;;
      -webkit-animation: 1s ease-in-out 0s 1 normal forwards running fadeIn;
  }
  #e-mail > i:hover {
    cursor: pointer;
    text-decoration: underline solid #34495e;
  }
  @-moz-keyframes scaleIn{
  0% {transform: scale(0);}
  50%{transform: scale(1.01);}
  75%{transform: scale(1);}
  100%{transform: scale(1);}
}
@-webkit-keyframes scaleIn{
  0% {transform: scale(0);}
  50%{transform: scale(1.01);}
  75%{transform: scale(1);}
  100%{transform: scale(1);}
}
@keyframes scaleIn{
  0% {transform: scale(0);}
  50%{transform: scale(1.01);}
  75%{transform: scale(1);}
  100%{transform: scale(1);}
}
@-moz-keyframes fadeIn{
    0%{opacity: 0;}
    100%{opacity: 1;}
  }
  @-webkit-keyframes fadeIn{
    0%{opacity: 0;}
    100%{opacity: 1;}
  }
  @keyframes fadeIn{
    0%{opacity: 0;}
    100%{opacity: 1;}
  }


  @-moz-keyframes fadePopIn{
    0%{opacity: 0;}
    100%{opacity: 1; transform: translateY(0);}
  }
  @-webkit-keyframes fadePopIn{
    0%{opacity: 0;}
    100%{opacity: 1; transform: translateY(0);}
  }
  @keyframes fadePopIn{
    0%{opacity: 0;}
    100%{opacity: 1; transform: translateY(0);}
  }

  @media (max-height: 500px){
    body {
      overflow: scroll!important;
    }
    #card{
      overflow: scroll!important;
    }
  }
  @media (max-width: 500px){


    .fa-copy {
      font-size: 1.5em!important;
      transform: translate(40px, 5px);
    }
    
    .fa-external-link{
      font-size: 1.5em!important;
      transform: translate(50px, 3px)!important;
    }
    .resume > b {
      font-size: 1.2em;
      
    }

    #toolTip {
      display: none!important;
    }
    body {
      overflow: scroll!important;
    }
    h1 {
      font-size: 1.6em!important;
    }
    #card{
      overflow: scroll!important;
      padding: 0!important;
      height: 700px;
      top: 50%!important;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    #layout > li:first-child{
      padding-top: 20%;
    }
    .history {
      margin: 0!important;
    }
    .resume {
      font-size: 1.6em;
      text-align: center;
      margin: 10px 8vw 10px 8vw !important;
      width: 80vw !important;
      height: 25px!important;
      padding: 10px 0 20px 0 !important;

    }
    .buttons {
      margin: 0 !important;
      margin-top: 10px !important;
      padding: 0 !important;
    }
  }

  