@font-face {
  font-family: 'open sans';
  src: url('assets/open_sans/opensans-extrabold-webfont.woff');
  src: url('assets/open_sans/opensans-extrabold-webfont.woff2') format('woff'),
      url('assets/open_sans/opensans-extrabold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}

hr {
  position: relative;
  opacity: .06;
  border-width: 1px;
  border-style: solid;
  border-color: black;
}

#name{
  margin: 15px auto;
  text-align: center;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  animation: 1s ease-in-out .15s 1 normal forwards running scaleIn;
  -moz-animation: 1s ease-in-out .15s 1 normal forwards running scaleIn;;
  -webkit-animation: 1s ease-in-out .15s 1 normal forwards running scaleIn;
  -webkit-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

.fa-copy::before {
  content: "\f0c5";
}

.fa-external-link{
  transform: translate(0, 1px);
}
.fa-external-link::before {
  content: "\f08e";
}

h1{
padding: 0;
margin: 0!important;
font-family: 'open sans';
font-size: 2rem;
user-select: none;
color: rgba(0,0,0,.7)!important;
}
      a {
  color: #34495e;
  text-decoration: none;
}
 #desc{
  margin: -20px auto 0 auto;
  text-align: center;
  cursor: default;
  user-select: none;
}
.subheading {
  padding: 4px;
  font-family: 'lane';
  font-size: 1.3rem;
  user-select: none;
}

.workHR {
  background: linear-gradient(to right, rgba(0,0,0,0), rgba(52, 73, 94, .3), rgba(0,0,0,0));
  border-width: 0;
  height: 2px;
}

.fadein {
  opacity: 0;
  animation: 1.5s ease-in-out 0s 1 normal forwards running fadeIn;
  -moz-animation: 1.5s ease-in-out 0s 1 normal forwards running fadeIn;;
  -webkit-animation: 1.5s ease-in-out 0s 1 normal forwards running fadeIn;
}

.history{
  background: rgb(255,253,250);
  background: linear-gradient(90deg, rgba(255,253,250,0) 0%, rgba(255,254,253,0.15) 20%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0.15) 80%, rgba(255,255,255,0) 100%);
  position: relative;
  margin: 0px 20px 20px 20px;
}

.tcnj {
  animation-delay: 0s;
}
.att {
  animation-delay: .25s;
}
.fb {
  animation-delay: .5s;
}
.ck {
  animation-delay: .75s;
}

.work {
  width:auto;
  max-width: 100px;
  max-height: 50px;
  margin: 9px;
  border-radius: 0;
}
.email-copy{
  font-style: italic;
  border: 1px solid rgba(52, 73, 94, .5);
  background-color: white !important;
  color: #34495e !important;
  animation-delay: .25s!important;
  padding: 8px 15px 8px 20px;

  margin-top: 15px!important;
  text-align: center;
  color: white;
  background: #34495e;
  opacity: 1;
  border-radius: 28px;
  font-size: 1em;
  font-family: 'lane';
}
@media (max-width: 600px) {
  .history {
    margin-bottom: 25px!important;
  }
  .fa-copy {
    transform: translateY(2px);
    font-size: 1.2rem!important;
    color: rgba(52, 73, 94, .3) !important;
  }
}

.email-copy:hover {
cursor: pointer;
text-decoration: underline solid #34495e;
}

.buttons {
  margin: 30px auto 30px auto;
}

.resume{
  cursor: pointer;
  text-align: center;
  color: white;
  font-style: normal;
  background: rgb(52, 73, 94, .8);
  background: rgba(0,0,0,.7);
  background: linear-gradient(
    90deg,
    rgba(0,0,0,.7) 0%,
    rgba(0,0,0,.6) 100%
  );
  opacity: .8;
  font-size: 1.2em;
  font-family: 'lane';
  padding: 9px 16px 15px 28px;
  margin-bottom: 10px;
  border-radius: 3px;
  border: 3px 1px 1px solid white;
  border-width: 3px;
  box-shadow: 1px 2px 2px rgba(0,0,0,.5);
  display: inline-block;
  opacity: 0;
  transition: all;
  transform: translateY(-10px);
  animation: .5s ease-in-out .5s 1 normal forwards running fadePopIn;
  -moz-animation: .5s ease-in-out .5s 1 normal forwards running fadePopIn;
  -webkit-animation: .5s ease-in-out .5s 1 normal forwards running fadePopIn;
}

.pdf {
  cursor: pointer;
  text-align: center;
  color: white;
  background: linear-gradient(
    90deg,
    rgb(82, 148, 148, 1) 0%,
    rgb(25, 75, 75) 100%
  );
  opacity: .8;
  font-size: 1em;
  font-family: 'lane';
  margin: 15px auto;
  padding: 15px 15px 15px 20px;
  box-shadow: 2px 2px 2px rgba(0,0,0,.5);
  display: block;
  opacity: 0;
  transition: all;
  transform: translateY(-10px);
  animation: .5s ease-in-out .5s 1 normal forwards running fadePopIn;
  -moz-animation: .5s ease-in-out .5s 1 normal forwards running fadePopIn;
  -webkit-animation: .5s ease-in-out .5s 1 normal forwards running fadePopIn;
  animation-delay: .2s;
  border: white;
  border-width: 3;
}
.pdf:hover {
  opacity: 1;
  background: rgba(25, 75, 75, .8);
}
.resume:hover {
  background: black;
 
}
.emailentry{
  display: list-item;
  padding: 10px;
  margin-top: 12px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  animation: 1s ease-in-out .35s 1 normal forwards running scaleIn;
  -moz-animation: 1s ease-in-out .35s 1 normal forwards running scaleIn;;
  -webkit-animation: 1s ease-in-out .35s 1 normal forwards running scaleIn;
}


@keyframes scalein {
  from {
    transform: scale(2);
  }
  to {
    transform: scale(1);
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.modal {
  z-index: 10;
  background: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: block;
  backdrop-filter: blur(7px);
  display: block;
  top: 0;
  position: fixed;
  margin: auto;
  padding: 0;
  animation: 0.2s ease-out 0s 1 fadein;
  
}
.modal-lg {
  width: 800px !important;
  margin: auto;
}
.closemodule {
  position: absolute;
  font-size: 40px;
  transform: translate(690px, -60px);
  cursor: pointer;
  border-radius: 100%;
  transition: 0.33s all;
}
.closemodule:hover {
  background: rgba(0, 0, 0, 0.3);
}
.resumebody {
  overflow: hidden;
}

.modalbody {  
  background: white;
  padding: 80px 50px;
  border-radius: 8px;
  height: 550px;
  margin: 8vh auto;
  transform: scale(1);
  animation: 0.2s ease-out 0s 1 scalein;
  overflow: hidden!important;
}

.ResumePortal {
  margin-top: 15px;
  width: 100%;
  height: 500px;
  overflow: hidden!important;
  opacity: 0;
  transition: all;
  transform: translateY(-10px);
  animation: 1s ease-in-out 0s 1 normal forwards running fadeIn;
  -moz-animation: 1s ease-in-out 0s 1 normal forwards running fadeIn;
  -webkit-animation: 1s ease-in-out 0s 1 normal forwards running fadeIn;
}

.searchicon{
  transform: translateY(4px);
  font-size: 1.2em;
}

.loader {
  position: fixed;

  z-index: 3;
  font-size: 5em;
}

.lds-heart {
  z-index: 3;
  display: inline-block;
  position: fixed;
  margin: 40% auto; /* Will not center vertically and won't work in IE6/7. */
  left: 0;
  right: 0;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  transform-origin: 40px 40px;
}
.lds-heart div {
  top: 32px;
  left: 32px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: #fdd;
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  background: #fdd;
}
.lds-heart div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
