img{
  user-select: none;
  height: 100px;
  width: 100px;
  border-radius: 50%;    
  -webkit-transform: scale(0);    
      -ms-transform: scale(0);    
          transform: scale(0);
  animation: 1s ease-in-out 0s 1 normal forwards running scaleIn;
  -moz-animation: 1s ease-in-out 0s 1 normal forwards running scaleIn;;
  -webkit-animation: 1s ease-in-out 0s 1 normal forwards running scaleIn;
  -webkit-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  opacity: 1;
}

.flip-container{
  user-select: none;
  -webkit-perspective: 1000;
          perspective: 1000;
}
.flip-container:hover .flipper, .flip-container.hover .flipper{
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.flip-container, .front, .back{
  width: 100px;
  height: 100px;
}
.flipper{
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  position: relative;
}
.front, .back{
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.front{
  z-index: 2;
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
}
.back{
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
#flippingContain{
  margin: auto;
  position: relative;
  display: block;
  width: 100px;
  height: 100px;
  padding-bottom: 10px;
}