body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  margin: 0;
  max-height: 100%;
  max-width: 100%;
  color: #151617;
  background-color: #f9fafb;
  background: linear-gradient(90deg, rgba(2, 0, 36, 0.29) 0%, rgba(9, 9, 121, 0.25) 35%, rgba(0, 212, 255, 0.353) 100%);
  background: -webkit-linear-gradient(90deg, rgba(2, 0, 36, 0.29) 0%, rgba(9, 9, 121, 0.25) 35%, rgba(0, 212, 255, 0.353) 100%);
  background: -moz-linear-gradient(90deg, rgba(2, 0, 36, 0.29) 0%, rgba(9, 9, 121, 0.25) 35%, rgba(0, 212, 255, 0.353) 100%);
  background: -o-linear-gradient(90deg, rgba(2, 0, 36, 0.29) 0%, rgba(9, 9, 121, 0.25) 35%, rgba(0, 212, 255, 0.353) 100%);
  background: linear-gradient(90deg, rgba(2, 0, 36, 0.29) 0%, rgba(9, 9, 121, 0.25) 35%, rgba(0, 212, 255, 0.353) 100%);
  
  /* background: -webkit-linear-gradient(left, #fff 2px, transparent 80%) center, -webkit-linear-gradient(#fff 2px, transparent 80%) center, #cccccc2e;
  background: -moz-linear-gradient(left, #fff 2px, transparent 80%) center, -moz-linear-gradient(#fff 2px, transparent 80%) center, #cccccc2e;
  background: -o-linear-gradient(left, #fff 2px, transparent 80%) center, -o-linear-gradient(#fff 2px, transparent 80%) center, #cccccc2e;
  background: linear-gradient(to right, #fff 2px, transparent 80%) center, linear-gradient(#fff 2px, transparent 80%) center, #cccccc2e; */
  /* background-size: 5px 5px; */
  cursor: default;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
