
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-github:before {
  content: "\f09b";
}
.fa-linkedin:before {
  content: "\f0e1";
}
.fa {
  padding: 0px 5px 0 0;
}
.fa-github, .fa-linkedin{
  font-size: 1.8rem !important;
  opacity: .8;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  margin: 0 8px 0 9px;
  -webkit-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}
.fa-envelope {
  font-size: 1.5rem !important;
}
.fa-github:hover, .fa-linkedin:hover{
  opacity: 1;
  /* -webkit-transform: scale(1.03);
      -ms-transform: scale(1.03);
          transform: scale(1.03); */
}
h1{
  padding: 0;
  margin: 0!important;
  font-size: 2rem;
  color: white;
}
a {
  color: #34495e;
  text-decoration: none;
}
